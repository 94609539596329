<template>
  <div class="wrapper">
    <h4>开卡</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="用户ID" prop="personId">
            <el-input v-model="form.personId" placeholder="请选择老人" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectPersonId()" />
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="tel">
            <el-input v-model="form.tel" placeholder="联系电话" auto-complete="off" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" auto-complete="off" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证号" prop="idNum">
            <el-input v-model="form.idNum" auto-complete="off" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-input v-model="form.sex" auto-complete="off" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="十进制卡号" prop="tmpCardNo">
            <el-input v-model="form.tmpCardNo" placeholder="请输入十进制卡号" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="fa fa-check" @click="changeCardNum()">卡号转化</el-button>
        </el-col>
        <el-col :span="8">
          <el-form-item label="物理卡号" prop="physicalCardNo">
            <el-input v-model="form.physicalCardNo" placeholder="物理卡号" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="逻辑卡号" prop="logicalCardNo">
            <el-input v-model="form.logicalCardNo" placeholder="请输入逻辑卡号" auto-complete="off" />
          </el-form-item>
        </el-col>
        <!--<el-col :span="8">
          <el-form-item label="描述" prop="description">
            <el-input v-model="form.description" placeholder="输入描述" auto-complete="off"></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="照片" prop="imgs">
            <upload-img :limit="1" :upload-lists="imgList" @uploadChange="uploadSuccess" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择人员" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="search" @reset="searchReset">
          <vxe-form-item title="老人姓名" span="12">
            <vxe-input v-model="searchData.personName" placeholder="请输入老人姓名" clearable />
          </vxe-form-item>
          <vxe-form-item title="联系电话" span="12">
            <vxe-input v-model="searchData.tel" placeholder="请输入联系电话" clearable />
          </vxe-form-item>
          <vxe-form-item title="身份证号" span="12">
            <vxe-input v-model="searchData.idNumber" placeholder="请输入身份证号" clearable />
          </vxe-form-item>
          <vxe-form-item align="center" span="12">
            <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
            <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
          </vxe-form-item>
        </vxe-form>
        <div style="margin-top:10px;">
          <el-table ref="singleTable" :data="tableData" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  v-model="radio"
                  :label="scope.$index"
                  style="color: #fff;padding-left: 10px; "
                  @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="name" width="100" label="姓名" align="center" />
            <el-table-column prop="sex" label="性别" width="60" align="center" />
            <el-table-column prop="idNum" label="身份证" width="180" align="center" />
            <el-table-column prop="tel" label="联系方式" width="120" align="center" />
            <el-table-column prop="address" label="地址" align="center" />
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              :current-page="tablePage.currentPage"
              :page-sizes="[50, 100, 500]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
export default {
  name: 'AddCard',
  components: {
    UploadImg
  },
  data() {
    return {
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      visible: false,
      searchData: {
        personName: '',
        idNumber: '',
        tel: ''
      },
      form: {},
      imgList: [],
      rules: {
        personId: [{ required: true, message: '请输入开卡ID', trigger: 'blur' }],
        tel: [{ required: true, message: '输入联系电话', trigger: 'blur' }],
        logicalCardNo: [{ required: true, message: '请输入逻辑卡号', trigger: 'blur' }],
        physicalCardNo: [{ required: true, message: '请输入物理卡号', trigger: 'blur' }]
        // description: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      }
    }
  },
  created() {
    this.initView()
    // this.getDicList()
  },
  methods: {
    ...mapActions(['downloadFile', 'searchPerson', 'addMealCard', 'changeMealNum']),
    getCurrentRow(row) {
      this.form.personId = row.id
      this.form.tel = row.tel
      this.form.idNum = row.idNum
      this.form.sex = row.sex
      this.form.name = row.name
    },
    cancel() {
      this.visible = false
    },
    search() {
      this.getConsConsultant()
    },

    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getConsConsultant()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      const params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          personName: this.searchData.personName,
          idNumber: this.searchData.idNumber,
          tel: this.searchData.tel
        }
      }
      const data = null
      const server = 'searchPerson'
      this[server](params).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    initView() {
      this.getConsConsultant()
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess(data) {
      this.form.imgs = data.join(',')
    },
    submitEvent(formName) {
      if (!this.form.personId) {
        this.$XModal.message({
          message: '请检查人员是否已选择',
          status: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form
          }
          const server = 'addMealCard'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    changeCardNum() {
      if (!this.form.tmpCardNo) {
        this.$XModal.message({
          message: '请输入十进制卡号',
          status: 'warning'
        })
        return
      }
      const params = { cardNum: '' }
      params.cardNum = this.form.tmpCardNo
      const server = 'changeMealNum'
      this[server](params).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.form.physicalCardNo = res.data.hexCardNum
          this.$forceUpdate()
        } else {
          this.$XModal.message({
            message: res.msg,
            status: 'error'
          })
        }
      })
    },
    selectPersonId() {
      this.visible = true
    },
    searchReset() {
      this.searchData = {}
      this.getConsConsultant()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
